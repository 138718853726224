import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { durationToObject } from "../../helpers";

function Timer({ initialTime, unit, tick, onFinish }) {
  const [time, setTime] = useState({});

  useEffect(() => {
    // Init time
    setTime(() => {
      return durationToObject({ duration: initialTime, unit });
    });
  }, []);

  useEffect(() => {
    // Start timer
    const interval = setInterval(() => {
      if (typeof time === "object" && Object.prototype.hasOwnProperty.call(time, "hour")) {
        let timeObject;
        // seconds
        if (Number.parseInt(time.second, 10) > 0) {
          timeObject = { ...time, second: time.second - 1 };
        } else if (Number.parseInt(time.second, 10) === 0 && Number.parseInt(time.minute, 10) > 0) {
          timeObject = { ...time, second: 59, minute: time.minute - 1 };
        } else if (Number.parseInt(time.minute, 10) === 0 && Number.parseInt(time.hour, 10) > 0) {
          timeObject = { ...time, minute: 59, hour: time.hour - 1 };
        } else {
          // call finish callback
          onFinish(interval);
          // reset time
          timeObject = false;
        }
        // setTime hook
        if (timeObject) {
          setTime(timeObject);
        }
      }
    }, tick);

    return () => clearInterval(interval);
  }, [time]);

  function renderClock() {
    let hours;
    let minutes;
    let seconds;

    if (time && Object.hasOwnProperty.call(time, "hour")) {
      hours = time.hour >= 10 ? time.hour : `0${time.hour}`;
      minutes = time.minute >= 10 ? time.minute : `0${time.minute}`;
      seconds = time.second >= 10 ? time.second : `0${time.second}`;
    }

    return <span>{`${hours}:${minutes}:${seconds}`}</span>;
  }

  return <div className="text-black">{renderClock()}</div>;
}

Timer.propTypes = {
  initialTime: PropTypes.number.isRequired,
  unit: PropTypes.string,
  tick: PropTypes.number,
  onFinish: PropTypes.func
};

Timer.defaultProps = {
  unit: "m",
  tick: 1000,
  onFinish: () => {}
};

export default Timer;
