import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swlert = withReactContent(Swal);

function ServerTimer({ finishTime, tick, onFinish }) {
  const calculateTimeLeft = () => {
    const difference = + new Date(finishTime.replace(/-/g,'/')) - + new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
        Swlert.fire({
            title: "TIME'S UP!",
            text: "Your time has elaspsed. You can no longer submit answers. Thanks for taking the coding test. We look forward to reviewing your answers.",
            confirmButtonText: "OK",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
          }).then(() => {
              // set timeup
              window.localStorage.setItem("timeup", true);
              // redirect to a new page
              window.location = "/finished";
          });
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, tick);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={timeLeft[interval]}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div key={timerComponents.length}>
      {/* <h2>Time Left:</h2> */}
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </div>
  );
}

export default ServerTimer;