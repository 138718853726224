import React from "react";

function Finished() {
  return (
    <div className="flex h-screen w-screen">
      <div className="flex flex-wrap content-start w-full p-12">
        <div className="flex w-full">
          <h1 className="text-4xl font-semibold text-gray-900">Thank you for taking the test.</h1>
        </div>
        <div className="flex w-full">
          <p className="text-base text-gray-700">
            We will contact you back if we believe your skills matches what we are looking for.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Finished;
