import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// Pages
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Test from "./pages/Test";
import Finished from "./pages/Finished";
// Assets
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/signup/:testId" exact component={Signup} />
        <Route path="/test/:id" exact component={Test} />
        <Route path="/finished" exact component={Finished} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
