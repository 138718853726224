import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import html2canvas from 'html2canvas';

// Components
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Clock } from "react-feather";
import Timer from "../components/timer";
import ServerTimer from "../components/timer/ServerTimer";

import Question from "../components/question";
// Icons

const Swlert = withReactContent(Swal);

function Test() {
  let finishTime = window.localStorage.getItem('endAt') || 0;
  let audio = new Audio('http://freesoundeffect.net/sites/default/files/sci-fi-beeperror-179-sound-effect-97326661.mp3');

  const [test, setTest] = useState({
    content: "",
    time: 0
  });
  const [question, setQuestion] = useState({});
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        // consume the api
        const response = await axios(`${process.env.REACT_APP_API_ENDPOINT}/test/${id}`);
        // destruct the response data
        const { result } = response.data;
        // set test state
        setTest(() => {
          return result;
        });
        // set default question
        setQuestion(() => {
          const currentQuestion = result ? result.questions[0] : {};
          // also save it to localStorage
          window.localStorage.setItem("currentQuestion", JSON.stringify(currentQuestion));
          // return the value
          return currentQuestion;
        });
      } catch (error) {
        Swlert.fire({
          type: "error",
          text: error.toString(error.toString())
        });
      }
    }
    // fetch data
    fetchData();
  }, [id]);

  // Check is candidate leaves browser or website while test is still active
  useEffect(() => {
    let testOver = window.localStorage.getItem('timeup') || false
    
    if (!testOver) {
      setInterval(function () {
        console.log('I am watching you!');
        checkTestTabActive();
     }, 10000); 
    }
  });

  function renderTimer() {
    if (test.time) {
      return (
        /*<Timer
          initialTime={test.time}
          unit="m"
          tick={1000}
          onFinish={interval => {
            // Clear interval
            window.clearInterval(interval);
            // Show alert
            Swlert.fire({
              text: "Time up",
              confirmButtonText: "OK",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false
            }).then(() => {
              // set timeup
              window.localStorage.setItem("timeup", true);
              // redirect to a new page
              window.location = "/finished";
            });
          }}
        />*/

        <ServerTimer
          finishTime={finishTime}
          tick={1000}
        />
      );
    }

    return null;
  }

  function handleQuestionSwitch(qst) {
    setQuestion(qst);
    // also save it to localStorage
    window.localStorage.setItem("currentQuestion", JSON.stringify(qst));
  }

  function checkTestTabActive() {
    if (document.hidden) {
      // candidate opened a new tab while test is ongoing: call his attention back
      // audio.play();

      // screenshot the page
      // html2canvas(document.body).then(canvas => {
      //   // convert the canvas to blob
      //   canvas.toBlob(blob => {
      //     // upload the blob to the server
      //     const formData = new FormData();
      //     formData.append("file", blob, "test.png");
      //     axios.post(`${process.env.REACT_APP_API_ENDPOINT}/test/upload`, formData, {
      //       headers: {
      //         "Content-Type": "multipart/form-data"
      //       }
      //     });
      //   });
      // });

     // screenshot the page opened in a new tab

      html2canvas( document.body).then((canvas) => {
          const base64image = canvas.toDataURL("image/png");
          // window.open(base64image);
          // console.log(base64image);
      });

      // show alert
      Swlert.fire({
        title: "Are you finished?",
        text: "We noticed that you left this tab before submitting your test. If you are finished, please submit your test. If you are not finished, please remember that outside resources are not allowed. Thank you and good luck.",
        confirmButtonText: "Understood",
        // imageUrl: 'https://media1.giphy.com/media/6ra84Uso2hoir3YCgb/200.gif',
        // imageWidth: 400,
        // imageHeight: 200,
        // imageAlt: 'I caught you lacking!',
      });
    }
  }

  return (
    <div className="w-full h-full flex flex-wrap content-start mx-auto">
      <div className="flex justify-start w-full bg-gray-300 p-4">
        <div className="flex justify-start items-center w-1/2">
          <h1 className="font-bold text-sm text-blue-600">{test.title}</h1>
        </div>
        <div className="flex justify-end items-center w-1/2">
          <div className="flex w-auto mr-1">
            <Clock size={15} />
          </div>
          <div className="flex w-auto font-semibold">{renderTimer()}</div>
        </div>
      </div>
      <div className="flex flex-wrap items-start content-start justify-start w-full h-full overflow-hidden">
        <div className="flex flex-wrap justify-start items-center content-start border-r shadow w-1/4 h-full">
          {test.questions
            ? test.questions.map((qst, index) => {
                return (
                  <div
                    className={`flex flex-wrap content-start hover:text-white p-3 border-b border-gray-200 w-full cursor-pointer ${
                      qst.id === question.id
                        ? "bg-blue-500 text-white"
                        : "hover:bg-gray-700 hover:border-gray-700 bg-white"
                    }`}
                    key={qst.id}
                    tabIndex={qst.id}
                    role="button"
                    onClick={() => {
                      handleQuestionSwitch(qst);
                    }}
                    onKeyDown={() => {
                      handleQuestionSwitch(qst);
                    }}
                  >
                    <span className="font-base">{`QUESTION ${index + 1}`}</span>
                  </div>
                );
              })
            : "Error fetching."}
        </div>
        <div className="flex p-3 w-3/4 h-full overflow-y-auto">
          <Question questionObject={question} />
        </div>
      </div>
    </div>
  );
}

export default Test;
