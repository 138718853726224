import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import pretty from "pretty";
import Editor from "./parts/editor";

function Question({ questionObject }) {
  const [question, setQuestion] = useState({});
  const [currentQuestionAnswer, setQuestionAnswer] = useState({});

  useEffect(() => {
    // set the question
    setQuestion(questionObject);

    return () => {
      setQuestion({});
    };
  }, [questionObject]);

  useEffect(() => {
    // get storage
    const questionAnswer = JSON.parse(window.localStorage.getItem("questionAnswer"));
    // set an existing answer
    questionAnswer.forEach(answer => {
      if (answer.questionId === question.id) {
        setQuestionAnswer(answer);
      }
    });

    return () => {
      setQuestionAnswer({});
    };
  }, [question]);

  return (
    <div className="flex flex-wrap content-start w-full py-4 px-6">
      <div className="flex w-full justify-center mb-4">
        <h1 className="font-bold text-lg">{question.title}</h1>
      </div>
      <div className="flex w-full">
        <div className="block leading-snug w-full mb-4">
          {parse(question.content ? pretty(question.content) : "")}
        </div>
      </div>
      <div className="flex w-full">
        <Editor
          questionContent={question.content}
          questionAnswer={currentQuestionAnswer.questionAnswer}
          questionExample={question.example}
        />
      </div>
    </div>
  );
}

Question.propTypes = {
  questionObject: PropTypes.object
};

Question.defaultProps = {
  questionObject: {}
};

export default Question;
