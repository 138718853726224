import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import MonacoEditor from "react-monaco-editor";

const Swlert = withReactContent(Swal);

function Editor({ questionContent, questionAnswer, questionExample }) {
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    // update the answer
    setAnswer(questionAnswer);

    return () => {
      setAnswer("");
    };
  }, [questionContent, questionAnswer]);

  async function submitAnswer() {
    const candidate = JSON.parse(window.localStorage.currentCandidate);
    const question = JSON.parse(window.localStorage.currentQuestion);
    // if answer is empty then ask to answer
    if (!answer) {
      Swlert.fire({
        text: "Are you sure about submitting an empty answer?",
        type: "question"
      });
    } else {
      // check if this question has been answered before
      const answeredQuestions = JSON.parse(window.localStorage.answeredQuestions);
      // if the answer exists then do not submit a new answer
      if (answeredQuestions && answeredQuestions.includes(question.id)) {
        const { data } = await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/answer`, {
          candidateId: candidate.id,
          questionId: question.id,
          questionAnswer: answer
        });
        // get saved answers
        let questionAnswerList = JSON.parse(window.localStorage.getItem("questionAnswer"));
        // fetch and update
        questionAnswerList = questionAnswerList.map(ans => {
          let newAnswer = ans;

          if (newAnswer.questionId === question.id) {
            newAnswer = data.result;
          }

          return newAnswer;
        });
        // update storage
        window.localStorage.setItem("questionAnswer", JSON.stringify(questionAnswerList));

        Swlert.fire({
          text: "Answer updated successfully. Continue to next question.",
          type: "success"
        });
      } else {
        const { data } = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/answer`, {
          candidateId: candidate.id,
          questionId: question.id,
          questionAnswer: answer,
          score: 0,
          timeTaken: new Date().toLocaleTimeString("en-US", {
            hour12: false,
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          })
        });
        // save answered questions
        window.localStorage.setItem(
          "answeredQuestions",
          JSON.stringify([...answeredQuestions, question.id])
        );
        // get saved answers
        const questionAnswerList = JSON.parse(window.localStorage.getItem("questionAnswer"));
        // fetch and update
        questionAnswerList.push(data.result);
        // update storage
        window.localStorage.setItem("questionAnswer", JSON.stringify(questionAnswerList));
        // Clear answer
        // setAnswer("");

        Swlert.fire({
          text: "Answer submitted successfully. Continue to next question.",
          type: "success"
        });
      }
    }
  }

  function editorDidMount(editor) {
    // set the focus
    editor.focus();
  }

  function onEditorChange(newValue) {
    // set new value
    setAnswer(newValue);
  }

  return (
    <div className="flex content-start flex-wrap w-full">
      <div className="flex w-full">
        <MonacoEditor
          height={600}
          language="javascript"
          theme="vs-dark"
          value={answer || questionExample}
          onChange={onEditorChange}
          editorDidMount={editorDidMount}
        />
      </div>
      <div className="flex w-full justify-end py-4">
        <button
          className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded"
          type="button"
          onClick={submitAnswer}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

Editor.propTypes = {
  questionContent: PropTypes.string,
  questionAnswer: PropTypes.string,
  questionExample: PropTypes.string
};

Editor.defaultProps = {
  questionContent: "",
  questionAnswer: "",
  questionExample: ""
};

export default Editor;
