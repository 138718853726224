import React from "react";

function Footer() {
  return (
    <div className="flex mt-2 mb-8 w-full">
      <div className="flex items-center justify-center w-full">
        <div className="link px-3">
          <a href="https://quickmedcards.com/about" className="cursor-pointer text-blue-500" rel="noopener noreferrer" target="_blank">About</a>
        </div>
        <div className="link px-3">
          <a href="https://quickmedcards.com/privacypolicy" className="cursor-pointer text-blue-500" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
        </div>
        <div className="link px-3">
          <a href="https://quickmedcards.com/terms-conditions" className="cursor-pointer text-blue-500" rel="noopener noreferrer" target="_blank">Terms of Service</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
