export const durationToDate = ({ duration, unit }) => {
  let time;
  // Convert to milliseconds
  switch (unit) {
    case "hours":
    case "hr":
    case "h":
      time = duration * 60 * 60 * 1000;
      break;
    case "minutes":
    case "min":
    case "m":
      time = duration * 60 * 1000;
      break;
    case "seconds":
    case "sec":
    case "s":
      time *= 1000;
      break;
    default:
      break;
  }
  // return a date object
  return new Date(time);
};

export const durationToObject = ({ duration, unit }) => {
  const timeDisplay = {
    hour: 0,
    minute: 0,
    second: 0
  };
  // Convert to milliseconds
  switch (unit) {
    case "hours":
    case "hr":
    case "h":
      timeDisplay.hour = Number.parseInt(duration, 10);
      break;
    case "minutes":
    case "min":
    case "m":
      timeDisplay.hour = Number.parseInt(duration / 60, 10);
      timeDisplay.minute = Number.parseInt(duration % 60, 10);
      break;
    case "seconds":
    case "sec":
    case "s":
      timeDisplay.hour = Number.parseInt(duration / 3600, 10);
      timeDisplay.minute = Number.parseInt(duration / 60, 10);
      timeDisplay.second = Number.parseInt(duration % 60, 10);
      break;
    default:
      break;
  }

  return timeDisplay;
};

export const dateTimeFormatter = date => {
  const intl = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });

  // if not a date then reject
  if (!(date instanceof Date)) {
    throw new Error("Date must be of object Date.");
  }

  return intl.formatToParts(date);
};

export const datePartsToObject = parts => {
  if (!Array.isArray(parts)) {
    throw new Error("Parts must be an array.");
  }
  // Reduce the parts to a new clean object
  const dateParts = parts.reduce((accumulator, currentValue) => {
    if (currentValue.type !== "literal") {
      // create a new entry
      accumulator[currentValue.type] = currentValue.value;
    }
    // return the accumulator
    return accumulator;
  }, {});

  return dateParts;
};

export const dateTimeFacade = ({ duration, unit }) => {
  try {
    // Create a date object
    const date = durationToDate({ duration, unit });
    // Format date to Intl parts
    const formattedDate = dateTimeFormatter(date);
    // Clean the parts to a clean object
    return datePartsToObject(formattedDate);
  } catch (error) {
    throw new Error(error.toString());
  }
};
