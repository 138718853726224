import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { List } from "react-feather";

function Signup() {
  const [creds, setCreds] = useState({
    email: "",
    firstName: "",
    lastName: "",
    yearsOfExperience: "",
    password: "",
    testId: ""
  });
  const [test, setTest] = useState({});
  const [error, setError] = useState({});
  const { testId } = useParams();

  useEffect(() => {
    // set the test id to state
    // setCreds({ ...creds, testId }); // deprecated
    
    // fetch test data
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/test/${testId}`)
      .then(({ data }) => {
        // set the test id to state
        setCreds({ ...creds, testId: data.result.id });
        return data.result ? setTest(data.result) : null;
      })
      .catch(err => {
        setError(err);
      });
  }, []);

  function handleInputChange(event) {
    const { name, value } = event.target;
    // update state
    setCreds({ ...creds, [name]: value });
  }

  function handleSubmit(event) {
    // prevent default action
    event.preventDefault();

    // set end time
    var endAt = moment().add(test.time, 'minutes').format('YYYY-MM-DD hh:mm A');

    // signup as a candidate
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/candidate/register`, creds)
      .then(({ data }) => {
        const { result } = data;
        // save to localStorage
        if (result) {
          // save creds and init answered questions
          window.localStorage.setItem("currentCandidate", JSON.stringify(result));
          window.localStorage.setItem("answeredQuestions", JSON.stringify([]));
          window.localStorage.setItem("questionAnswer", JSON.stringify([]));
          window.localStorage.setItem("endAt", endAt);
          // redirect to test
          window.location.pathname = `/test/${testId}`;
        }

        return false;
      });
  }

  return (
    <div className="flex content-center w-full">
      <div className="flex hidden">{error.toString()}</div>
      <div className="flex flex-wrap justify-center items-center p-3 w-full">
        <div className="flex flex-wrap items-center py-5 w-full">
          <div className="flex justify-center w-full mb-5">
            <h1 className="text-center text-3xl text-blue-500 font-semibold">{test.title}</h1>
          </div>
          <div className="flex flex-no-wrap justify-center w-full mb-5">
            <div className="flex items-center justify-center w-full">
              <div className="w-auto mr-1">
                <List size={15} />
              </div>
              <div className="w-auto">
                <span className="uppercase text-xs text-blue-600 border-dashed border-b cursor-pointer">
                  {test.questions ? `${test.questions.length} questions` : "0 questions"}
                </span>
              </div>
              <span className="mx-1">●</span>
              <div className="w-auto">
                <span className="uppercase text-xs">{`${test.time} minutes`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full">
          <div className="flex flex-wrap items-center justify-center w-11/12 xl:w-7/12 bg-white border border-gray-400 rounded px-8 pt-6 pb-8 mb-4">
            <div className="flex flex-wrap justify-center items-center w-full">
              <div className="w-full">
                <span className="text-blue-500 capitalize">instructions</span>
              </div>
              <div className="w-full my-3" />
              <div className="w-full">
                <ol className="list-decimal text-gray-700 font-thin pl-10">
                  <li className="mb-2">This is a programming test</li>
                  <li className="mb-2">
                    If the code area contains the function signature, just complete the function
                    alone. We will take care of the main function, headers, etc.
                  </li>
                  <li className="mb-2">
                    Please close all other applications and tabs before taking the test.
                  </li>
                  <li className="mb-2">
                    Take the test in an incognito/private tab to avoid any of your extensions
                    causing issues while taking the test.
                  </li>
                </ol>
              </div>
            </div>
            <div className="flex w-full border-gray-300 border-b px-4 my-5" />
            <div className="flex justify-center items-center w-full mt-4 mb-8">
              <span className="text-lg text-gray-800">
                Complete this form to start the challenge.
              </span>
            </div>
            <div className="flex justify-center items-center w-full">
              <form className="w-9/12" onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold" htmlFor="email">
                    <span className="block mb-2">Email</span>
                    <input
                      className="border shadow shadow-inner border-gray-400 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="email"
                      name="email"
                      id="email"
                      value={creds.email}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold" htmlFor="firstName">
                    <span className="block mb-2">First Name</span>
                    <input
                      className="border shadow shadow-inner border-gray-400 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={creds.firstName}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold" htmlFor="lastName">
                    <span className="block mb-2">Last Name</span>
                    <input
                      className="border shadow shadow-inner border-gray-400 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={creds.lastName}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 font-semibold" htmlFor="yearsOfExperience">
                    <span className="block mb-2">Years of Work Experience</span>
                    <input
                      className="border shadow shadow-inner border-gray-400 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="number"
                      name="yearsOfExperience"
                      min="0"
                      id="yearsOfExperience"
                      value={creds.yearsOfExperience}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 font-semibold" htmlFor="yearsOfExperience">
                    <span className="block mb-2">Test Password</span>
                    <input
                      className="border shadow shadow-inner border-gray-400 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="password"
                      name="password"
                      min="0"
                      id="password"
                      value={creds.password}
                      onChange={handleInputChange}
                    />
                  </label>
                </div>
                <div className="flex items-center justify-center">
                  <button
                    type="submit"
                    className="btn-inverse bg-blue-500 hover:bg-blue-700 font-thin text-lg text-white py-3 px-5 rounded focus:outline-none focus:shadow-outline"
                  >
                    Start challenge
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center w-full">
          <div className="flex justify-center w-full mb-3">
            <p className="text-gray-700">Powered by</p>
          </div>
          <div className="flex justify-center w-full mb-3">
            <p className="text-gray-700">the software engineering team of</p>
          </div>
          <div className="flex justify-center w-full">
            <img
              className="w-48"
              src="https://quickmedcards.com/assets/customer/images/qmc-flower-small.png"
              alt="QuickMedCards"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
